import React from "react"
import CreateBanner from "../components/createBanner"
import Layout from "../components/layout"
import { ComingSoon } from "../images/comingSoon"

const Blog = () => {
  return (
    <Layout>
      <CreateBanner>
        <ComingSoon />
      </CreateBanner>
    </Layout>
  )
}

export default Blog
