import React from "react"

export const ComingSoon = ({ color }) => {
  return (
    <svg viewBox="0 0 1175 556" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 200.394C0 256.926 36.6638 290.065 92.4396 290.455C112.722 280.708 145.095 246.399 151.336 237.042L145.875 233.923C139.634 243.28 121.693 266.283 103.361 266.283C60.0662 266.283 36.6638 228.855 36.6638 181.29C36.6638 143.863 53.0455 103.706 85.0288 103.706C100.24 103.706 111.552 136.845 120.523 149.711C133.784 150.88 141.585 150.101 151.726 145.812C150.946 116.962 123.253 97.468 92.4396 97.468C41.7343 105.265 0 149.711 0 200.394Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M343.687 190.258C343.687 132.946 314.044 98.6376 256.708 97.468C233.305 99.4174 159.978 139.964 159.978 196.495C159.978 253.807 188.451 290.455 245.786 290.455C263.728 288.505 343.687 258.485 343.687 190.258ZM259.048 277.589C232.915 277.589 195.861 243.28 195.861 185.579C195.861 148.151 206.002 112.673 243.446 112.673C272.309 112.673 307.803 151.27 307.803 205.073C307.803 230.804 296.492 277.589 259.048 277.589Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M640.181 279.148V169.204C640.181 124.759 623.8 99.8072 579.335 97.468C572.314 97.8579 541.891 118.521 523.949 138.794C517.319 113.453 499.767 99.0275 466.613 97.468C459.203 97.8579 430.34 120.47 409.277 141.524H407.717L411.228 102.926L406.547 102.146H351.161V104.486L377.684 111.114V279.148L351.551 283.437V285.386H438.14V283.437L412.398 279.148V144.253C431.51 125.539 451.402 124.369 461.153 124.369C478.315 124.369 492.746 135.286 492.746 175.442V279.148L466.223 283.437V285.386H556.713V283.437L527.46 279.148V169.204C527.46 159.848 526.68 151.66 525.119 143.863C540.721 125.539 564.123 124.369 573.875 124.369C591.036 124.369 605.468 135.286 605.468 175.442V279.148L578.945 283.437V285.386H669.434V283.437L640.181 279.148Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M720.914 40.9366C732.225 40.9366 741.196 31.9695 741.196 20.2734C741.196 8.96706 732.225 0 720.914 0C709.602 0 700.632 8.96706 700.632 20.2734C700.632 31.9695 709.602 40.9366 720.914 40.9366ZM676.449 285.386H763.038V283.437L737.295 279.148V102.926L732.615 102.146H680.349V104.486L702.582 108.774V279.148L676.449 283.437V285.386Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M769.388 104.486L795.911 111.114V279.148L769.778 283.437V285.386H857.147V283.437L830.625 279.148V144.253C850.127 125.539 872.749 124.369 882.5 124.369C899.662 124.369 914.093 135.286 914.093 175.442V279.148L887.571 283.437V285.386H974.94V283.437L948.807 279.148V169.204C948.807 124.759 932.425 99.8072 887.961 97.468C880.55 97.8579 848.567 120.47 827.504 141.524H825.944L829.455 102.926L824.774 102.146H769.388V104.486Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M983.1 316.186C983.1 350.495 994.021 371.548 1061.89 373.887C1066.96 373.887 1072.03 373.887 1077.1 373.497C1091.53 373.497 1164.86 348.935 1165.64 311.898C1166.42 271.741 1129.37 262.384 1077.49 260.434C1067.74 260.045 1033.42 258.485 1029.9 258.095C1025.22 257.705 1020.54 256.146 1020.54 249.908C1020.93 239.771 1026.39 234.703 1032.64 229.635C1041.61 232.364 1051.75 233.923 1062.67 233.923C1084.51 233.923 1143.02 192.987 1143.02 160.237C1143.02 141.524 1134.83 125.539 1120.78 114.232C1122.34 113.843 1124.29 113.843 1126.24 113.843C1130.93 113.843 1155.89 121.25 1162.52 121.25C1167.59 120.86 1172.27 115.402 1175 103.316L1154.33 97.468C1154.33 107.215 1141.46 108.774 1132.49 108.774C1120.39 108.774 1114.15 107.605 1105.18 104.876C1094.26 100.197 1081 97.468 1066.18 97.468C1042.39 97.468 985.44 129.827 985.44 169.204C985.44 195.326 1001.43 217.938 1028.73 228.075C1020.54 237.042 1009.62 246.399 1003.38 257.315C1003.38 269.791 1017.81 286.166 1026.78 286.166H1029.12C1014.3 296.303 996.361 305.27 983.1 316.186ZM1020.93 161.797C1020.93 123.979 1037.32 104.096 1063.45 104.096C1090.75 104.096 1107.52 124.369 1107.52 167.255C1107.52 203.123 1089.58 227.295 1063.45 227.295C1034.98 227.295 1020.93 200.784 1020.93 161.797ZM1019.37 321.644C1019.37 309.948 1024.83 298.252 1038.1 286.166C1101.67 286.556 1136.39 290.455 1136.39 322.034C1136.39 345.816 1120 366.09 1065.4 364.53C1040.83 363.751 1019.37 347.766 1019.37 321.644Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M13.3897 473.542V547.411C34.0131 550.417 61.0813 552.994 113.069 555.141C161.191 555.141 201.578 534.956 201.578 486.426C201.578 445.627 168.495 428.877 118.655 406.975C98.0315 397.956 67.0964 393.232 67.0964 373.476C67.0964 358.874 80.4157 347.708 95.0239 344.272H95.4536L137.13 403.968H189.118L188.689 342.984C161.62 340.407 134.122 337.401 107.914 336.971C63.6592 336.971 19.4048 358.874 19.4048 404.827C19.4048 462.376 66.6668 474.401 100.609 487.285C128.107 497.592 150.879 502.746 151.738 518.207C152.598 534.956 134.122 546.981 116.507 546.981H115.647L45.6137 473.542H13.3897Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M228.955 446.056C228.955 510.906 280.084 556 343.243 556C406.402 556 457.531 510.906 457.531 446.056C457.531 381.206 406.402 336.112 343.243 336.112C280.084 336.112 228.955 381.206 228.955 446.056ZM311.019 446.056C311.019 386.79 325.198 343.843 343.243 343.843C361.289 343.843 375.467 386.79 375.467 446.056C375.467 505.323 361.289 548.27 343.243 548.27C325.198 548.27 311.019 505.323 311.019 446.056Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M485.321 446.056C485.321 510.906 536.45 556 599.609 556C662.768 556 713.897 510.906 713.897 446.056C713.897 381.206 662.768 336.112 599.609 336.112C536.45 336.112 485.321 381.206 485.321 446.056ZM567.385 446.056C567.385 386.79 581.563 343.843 599.609 343.843C617.654 343.843 631.833 386.79 631.833 446.056C631.833 505.323 617.654 548.27 599.609 548.27C581.563 548.27 567.385 505.323 567.385 446.056Z"
        fill={color ? color : "#E4D7D1"}
      />
      <path
        d="M737.441 553.423H860.322V552.994L839.698 528.085V419.859C843.135 412.558 850.44 408.263 858.603 408.263C869.774 408.263 879.656 415.993 879.656 430.595V553.423H981.914V552.994L961.29 528.085V399.244C961.29 361.88 938.948 336.112 905.006 336.112C869.344 336.112 851.728 360.592 839.698 399.244V338.689H737.441V339.119L758.064 364.028V528.085L737.441 552.994V553.423Z"
        fill={color ? color : "#E4D7D1"}
      />
    </svg>
  )
}
